.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background: #1c2837;
  color: white;
  cursor: pointer;
}

.header__left .header__left-title {
  margin: 0;
}

.header__content {
  display: flex;
  align-items: center;
}

.header__content-item {
  margin-right: 12px;
}

.header__content-item p {
  margin: 0;
}
.header__content-item span {
  color: #a6a6a6;
}
