#wrapper {
  background: #f8f8f8;
  height: calc(100vh - 55px);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.login-layout {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}

.login-form__wrapper {
  background: #fff;
  display: grid;
  justify-content: center;
  padding: 18px;
  border: 1px solid #ececec;
  border-radius: 2px;
  box-shadow: 0 0 2px #efefef;
  /* margin-top: 10rem; */
}

.login-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
}

.button {
  outline: none;
  background: white;
  border: none;
  border-radius: 2px;
  height: 1.7rem;
  padding: 6px;
  cursor: pointer;
}

.graph-section .graph-section__title {
  display: flex;
  column-gap: 8px;
  align-items: baseline;
}

.graph-list .graph-card {
  margin-bottom: 12px;
}

.graph-index-wrapper {
  padding: 12px 24px;
}

.graph-list-add-new {
  padding: 18px 24px;
}
