.graph-card {
  background: #fff;
  padding: 18px 24px;
  border: 1px solid #ececec;
  border-radius: 2px;
  box-shadow: 0 1px 1px #eee;
}

.graph-card:hover {
  cursor: pointer;
  background: #f8f8f8;
}

.graph-card__left-title {
  margin: 0;
}
